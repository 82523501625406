import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.jsx";
import { LoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.jsx";
import { PortalModal } from "clutch/src/PortalModal/PortalModal.js";
import { Select } from "clutch/src/Select/Select.jsx";
import type { ElementOf } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import AgentAbilityToggle from "@/game-val/components/AgentAbilityToggle.jsx";
import LineupModal from "@/game-val/components/LineupModal.jsx";
import SideToggle from "@/game-val/components/SideToggle.jsx";
import type { Sides } from "@/game-val/guides-utils.mjs";
import {
  DIFFICULTY_OPTIONS,
  getGuideKeyStr,
  getGuidesData,
} from "@/game-val/guides-utils.mjs";
import SelectAgent from "@/game-val/SelectAgent.jsx";
import SelectMap from "@/game-val/SelectMap.jsx";
import { useLineupMapper } from "@/game-val/utils/lineups";
import ReloadIcon from "@/inline-assets/reload.svg";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import Sentinel from "@/shared/Sentinel.jsx";
import type { LineupCardProps } from "@/shared-fps/LineupCards.jsx";
import LineupCards from "@/shared-fps/LineupCards.jsx";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import titleCase from "@/util/title-case.mjs";
import { useInteractionEvent } from "@/util/use-interaction-event.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Lineups() {
  const { t } = useTranslation();
  const { searchParams, currentPath, state } = useRoute();
  const {
    val: {
      cms: { agents },
      tips: { list },
    },
  } = useSnapshot(readState);
  const {
    page,
    pages,
    items: lineupList,
  } = getGuidesData(list, searchParams, true);

  const [loadingPage, setLoadingPage] = useState(0);

  const [shownLineup, setShownLineup] = useQuery<string | null>("lineup", null);
  const [difficulty, setDifficulty] = useQuery<
    ElementOf<typeof DIFFICULTY_OPTIONS>["value"] | null
  >("difficulty", DIFFICULTY_OPTIONS?.[0]?.value);
  const [side, setSide] = useQuery<Sides>("side", "Attacking");
  const [map, setMap] = useQuery<string | null>("map", "all");
  const [agent, setAgent] = useQuery<string | null>("agent", "all");
  const [ability, setAbility] = useQuery<string | null>("ability", "all");

  const guidesKey = getGuideKeyStr(searchParams, true);

  const userInteraction = useInteractionEvent("val-lineups");

  const lineupMapper = useLineupMapper();

  useEffect(() => {
    // Reset page when params change
    setLoadingPage(0);
  }, [guidesKey]);

  const cmsAgent = agents.find((a) => a.key === agent);

  useEffect(() => {
    // Reset ability when agent changes
    if (agent === "all" && ability !== "all") setAbility("all");

    const agentAbilities = cmsAgent?.abilities.map((a) => a.key) || [];

    if (ability !== "all" && !agentAbilities.includes(ability))
      setAbility("all");
  }, [agent, cmsAgent, ability, setAbility]);

  const modalRef = useRef(null);

  const onAbilityChange = useCallback(
    (ability: string) => {
      userInteraction("set-ability", { ability }, "click");
      setAbility(ability);
    },
    [setAbility, userInteraction],
  );

  const onModalClose = useCallback(() => {
    userInteraction("modal-close", { shownLineup }, "click");
    setShownLineup(null);
  }, [shownLineup, setShownLineup, userInteraction]);

  const resetFilters = useCallback(() => {
    setDifficulty(DIFFICULTY_OPTIONS?.[0]?.value);
    setSide("Attacking");
    setMap("all");
    setAgent("all");
    setAbility("all");
  }, [setDifficulty, setSide, setMap, setAgent, setAbility]);

  const isDefaultFilters = useMemo(() => {
    return (
      difficulty === DIFFICULTY_OPTIONS?.[0]?.value &&
      side === "Attacking" &&
      map === "all" &&
      agent === "all" &&
      ability === "all"
    );
  }, [difficulty, side, map, agent, ability]);

  useEffect(() => {
    if (!modalRef?.current) return;
    if (shownLineup) {
      if (modalRef.current.open) return;
      modalRef.current.showModal();
      userInteraction("modal-open", { shownLineup }, "click");
    } else {
      modalRef.current.close();
    }
  }, [shownLineup, userInteraction]);

  const lineups: LineupCardProps[] = useMemo(() => {
    if (!lineupList) return [];

    return lineupList.map(lineupMapper);
  }, [lineupList, lineupMapper]);

  const loadNextPage = useCallback(
    (isVisible: boolean) => {
      if (!isVisible || page >= pages || loadingPage > page) return;

      const routeStateNext = { ...state };
      routeStateNext.transient = Object.assign(
        routeStateNext.transient ? { ...routeStateNext.transient } : {},
        {
          page,
        },
      );
      setLoadingPage(page + 1);
      (routeStateNext.transient as { page: number }).page += 1;
      updateRoute(currentPath, searchParams, routeStateNext);
    },
    [page, pages, currentPath, searchParams, loadingPage, state],
  );

  return (
    <PageContainer>
      <PageHeader title={["common:lineups", "Lineups"]} />
      <PortalModal forwardedRef={modalRef} onModalClose={onModalClose}>
        <LineupModal id={shownLineup} />
      </PortalModal>
      <div className="flex wrap gap-2">
        <SelectAgent
          withIcons
          selected={agent}
          onChange={(value) => {
            userInteraction("set-agent", { agent: value }, "click");
            setAgent(value);
          }}
        />
        <SelectMap
          withIcons
          selected={map}
          onChange={(value) => {
            userInteraction("set-map", { map: value }, "click");
            setMap(value);
          }}
          excludeOutOfRotation={false}
        />
        <SideToggle side={side} setSide={setSide} />
        <Select
          selected={difficulty}
          options={DIFFICULTY_OPTIONS}
          onChange={(value) => {
            userInteraction("set-difficulty", { difficulty: value }, "click");
            setDifficulty(value);
          }}
        />
        {!isDefaultFilters && (
          <Button onClick={resetFilters}>
            <div className="flex gap-2 align-center shade1">
              <ReloadIcon />
              <span className="type-callout--semi">
                {t("common:reset", "Reset")}
              </span>
            </div>
          </Button>
        )}
        <div className="flex-divider" />
        {agent !== "all" && cmsAgent ? (
          <AgentAbilityToggle
            agent={cmsAgent}
            ability={ability}
            setAbility={onAbilityChange}
          />
        ) : null}
      </div>
      <LineupCards lineups={lineups} isLoading={loadingPage > page} />
      <Sentinel as="div" onVisible={loadNextPage} />
      {loadingPage > page && pages !== -1 && (
        <LoadingSpinner $position="relative" />
      )}
    </PageContainer>
  );
}

export function meta(_params, searchParams): Meta {
  const map = searchParams.get("map");
  const mapName = map ? titleCase(map) : null;

  if (!mapName)
    return {
      title: ["val:meta.lineups.title", "Valorant Lineups"],
      description: [
        "val:meta.lineups.description",
        "Learn the best lineups in VALORANT with our expert guides. Find the perfect strategy for attacking and defending in every map.",
      ],
    };

  return {
    title: [
      "val:meta.mapLineups.title",
      "Valorant {{mapName}} Lineups",
      { mapName },
    ],
    description: [
      "val:meta.mapLineups.description",
      "Learn the best lineups for {{mapName}} in VALORANT with our expert guides. Find the perfect strategy for attacking and defending in every map.",
      { mapName },
    ],
  };
}
