import React, { useMemo } from "react";
import { styled } from "goober";
import type {
  ReactZoomPanPinchHandlers,
  ReactZoomPanPinchProps,
} from "@pronestor/react-zoom-pan-pinch";
import {
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch";
import { mobile } from "clutch/src/Style/style.mjs";
import type { DeepReadonly } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import AgentAbilityIcon from "@/game-val/AgentAbilityIcon.jsx";
import DifficultyDot from "@/game-val/DifficultyDot.jsx";
import type { Agents } from "@/game-val/models/agents.mjs";
import type { Tips } from "@/game-val/models/tips.mjs";
import { getHSLColor } from "@/game-val/utils.mjs";
import keyInObject from "@/util/key-in-object.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MAP_OPTIONS: ReactZoomPanPinchProps = {
  limitToBounds: true,
  centerOnInit: true,
  centerZoomedOut: true,
  maxScale: 5,
  minScale: 0.5,
  wheel: {
    step: 0.9,
  },
};

type MapContentProps = {
  zoomIn: ReactZoomPanPinchHandlers["zoomIn"];
  zoomOut: ReactZoomPanPinchHandlers["zoomOut"];
  setTransform: ReactZoomPanPinchHandlers["setTransform"];
  otherLineups?: DeepReadonly<Tips> | Tips;
  lineup: DeepReadonly<Tips[number]> | Tips[number];
};

const MapPlaceholder = styled("svg")`
  display: block;
  width: auto;

  ${mobile} {
    height: auto;
    width: 100%;
  }
`;

const TransformOuter = styled("div")`
  aspect-ratio: 16 / 9;
  overflow: hidden;
  background-color: var(--shade8);

  .map-wrapper {
    max-width: 100%;
    max-height: 100%;
  }
`;
const MapFrame = styled("div")`
  position: relative;
  height: 0;
  margin: var(--sp-6) 0;
  padding-bottom: 100%;

  .map-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const MapFrameInner = styled("div")`
  position: relative;

  img.layout-img,
  div.tips-frame {
    transform: rotate(var(--rotation-degree));
  }

  ${mobile} {
    height: auto;
    width: 100%;
  }

  .tips-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const LineupIconWrapper = styled("div")`
  position: absolute;
  top: var(--lineup-y);
  left: var(--lineup-x);
  color: var(--shade0);
  transform: translate(-50%, -50%)
    rotate(calc(var(--rotation-degree, 0deg) * -1));
  border-radius: 50%;
  border: var(--sp-0_5) solid transparent;
  cursor: pointer;
  width: var(--sp-8);
  height: var(--sp-8);
  padding: var(--sp-1);

  &:hover,
  &.active {
    background: var(--agent-color-transparent);
    border-color: var(--agent-color);

    transform: translate(-50%, -50%)
      rotate(calc(var(--rotation-degree, 0deg) * -1)) scale(1.1);
    z-index: 2;
  }

  img {
    color: var(--agent-color-light);
    opacity: 1;
    max-width: 100%;
    max-height: 100%;
  }
`;

type LineupIconProps = {
  positionX: number;
  positionY: number;
  ability: DeepReadonly<Agents[number]["abilities"][number]>;
  difficulty: string;
  className?: string;
  href?: string;
};

function LineupIcon({
  positionX,
  positionY,
  ability,
  difficulty,
  className,
  href,
}: LineupIconProps) {
  const linkProps = href ? { as: "a" as const, href } : {};
  return (
    <LineupIconWrapper
      style={{ "--lineup-x": `${positionX}%`, "--lineup-y": `${positionY}%` }}
      className={className}
      {...linkProps}
    >
      <AgentAbilityIcon ability={ability} />
      <DifficultyDot difficulty={difficulty} />
    </LineupIconWrapper>
  );
}

function MapContent({ otherLineups, lineup }: MapContentProps) {
  const { val } = useSnapshot(readState);

  const selectedMap = useMemo(() => {
    const maps = val.cms.maps ?? [];
    return maps.find((m) => m.key === lineup.map.key);
  }, [val?.cms?.maps, lineup.map.key]);

  const agentKey = lineup.agent.key;
  const agent = val.cms.agents.find((a) => a.key === agentKey);
  const ability = agent?.abilities.find((a) => a.key === lineup.abilityKey);
  const agentColorVars = useMemo(() => {
    if (!agent) return undefined;
    const agentColor = getHSLColor(agent.color);

    return {
      "--agent-color": `hsl(${agentColor})`,
      "--agent-color-light": `hsl(${agentColor} / 0.9)`,
      "--agent-color-dark": `hsl(${agentColor} * 0.9)`,
      "--agent-color-transparent": `hsla(${agentColor}, 0.2)`,
      "--agent-color-transparent-light": `hsla(${agentColor}, 0.75)`,
    };
  }, [agent]);

  const side = "defending";
  const sideOrientation =
    selectedMap?.images?.side_orientations &&
    keyInObject(selectedMap?.images?.side_orientations, side)
      ? selectedMap.images.side_orientations[side]
      : 0;

  if (!selectedMap) return null;

  return (
    <TransformOuter style={agentColorVars}>
      <TransformComponent wrapperClass="map-wrapper">
        <MapFrame>
          <MapFrameInner
            style={{
              "--rotation-degree":
                typeof sideOrientation === "number"
                  ? `${sideOrientation}deg`
                  : `0deg`,
            }}
          >
            <MapPlaceholder width="800" height="800" />
            <img
              src={selectedMap?.images?.layout}
              alt={selectedMap?.name}
              className="map-img layout-img"
            />
            {selectedMap?.images?.walls &&
            keyInObject(selectedMap.images.walls, side) ? (
              <img
                src={selectedMap.images.walls[side]}
                alt={selectedMap?.name}
                className="map-img"
              />
            ) : null}
            {selectedMap?.images?.labels &&
            keyInObject(selectedMap.images.labels, side) ? (
              <img
                src={selectedMap.images.labels[side]}
                className="map-img map-labels"
              />
            ) : null}
            {ability ? (
              <LineupIcon
                positionX={lineup.locationX}
                positionY={lineup.locationY}
                ability={ability}
                difficulty={lineup.difficulty}
                className="active"
              />
            ) : null}
            {otherLineups && otherLineups.length > 0
              ? otherLineups.map((l) => {
                  const ability = agent?.abilities.find(
                    (a) => a.key === l.abilityKey,
                  );
                  const lineupParams = new URLSearchParams();
                  lineupParams.set("agent", agentKey);
                  lineupParams.set("map", l.map.key);
                  lineupParams.set("lineup", l.sys.id);
                  if (!ability) return null;
                  return (
                    <LineupIcon
                      key={l.sys.id}
                      positionX={l.locationX}
                      positionY={l.locationY}
                      ability={ability}
                      difficulty={l.difficulty}
                      href={`/valorant/lineups?${lineupParams}`}
                    />
                  );
                })
              : null}
          </MapFrameInner>
        </MapFrame>
      </TransformComponent>
    </TransformOuter>
  );
}

type LineupMapProps = {
  lineup: DeepReadonly<Tips[number]> | Tips[number];
  otherLineups?: DeepReadonly<Tips> | Tips;
};

export default function LineupMap({ otherLineups, lineup }: LineupMapProps) {
  return (
    <TransformWrapper {...MAP_OPTIONS}>
      {({ zoomIn, zoomOut, setTransform }) => (
        <MapContent
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          setTransform={setTransform}
          otherLineups={otherLineups}
          lineup={lineup}
        />
      )}
    </TransformWrapper>
  );
}
