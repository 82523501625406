import React, { useCallback } from "react";
import { styled } from "goober";
import type { TagProps } from "clutch/src/Tag/Tag.jsx";
import type { DeepReadonly } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import AbilityIcon from "@/game-val/AgentAbilityIcon.jsx";
import DifficultyDot from "@/game-val/DifficultyDot.jsx";
import type { Agents } from "@/game-val/models/agents.mjs";
import type { Tips } from "@/game-val/models/tips.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import titleCase from "@/util/title-case.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export function getLineupTags(
  lineup: Tips[number] | DeepReadonly<Tips[number]>,
  ability: DeepReadonly<Agents[number]["abilities"][number]>,
): TagProps[] {
  return [
    {
      color: "var(--shade1)",
      text: ability.name,
      iconLeft: <AbilityIcon ability={ability} />,
      size: "small",
      as: "a",
      href: `/valorant/lineups?agent=${lineup.agent.key}&ability=${lineup.abilityKey}`,
    },
    {
      color: "var(--shade1)",
      text: lineup.map.name,
      size: "small",
      as: "a",
      href: `/valorant/lineups?map=${lineup.map.key}`,
    },
    {
      color: "var(--shade1)",
      text: titleCase(lineup.difficulty),
      iconLeft: <DifficultyDot difficulty={lineup.difficulty} />,
      size: "small",
      as: "a",
      href: `/valorant/lineups?difficulty=${lineup.difficulty}`,
    },
  ];
}

const Icon = styled("img")`
  max-width: 100%;
  max-height: 100%;
`;

export function useLineupMapper() {
  const { currentPath, searchParams } = useRoute();
  const {
    val: {
      cms: { agents, maps },
    },
  } = useSnapshot(readState);

  return useCallback(
    (lineup: Tips[number]) => {
      const nextParams = new URLSearchParams(searchParams);
      nextParams.set("lineup", lineup.sys.id);
      const map = maps.find((m) => lineup.map.key === m.key);
      const agentKey = lineup.agent.key;
      const agent = agents.find((a) => a.key === agentKey);
      const ability = agent?.abilities.find((a) => a.key === lineup.abilityKey);
      return {
        id: lineup.sys.id,
        title: lineup.title,
        href: `${currentPath}?${nextParams.toString()}`,
        imgSrc: lineup.thumbnail?.url || lineup.video.url,
        icon: <Icon src={agent?.images.matchtile.url} />,
        tags: ability ? getLineupTags(lineup, ability) : [],
        fallbackImg: map?.images?.hero,
      };
    },
    [currentPath, searchParams, agents, maps],
  );
}
