import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";
import { Tabs } from "clutch/src/Tabs/index.jsx";
import type { DeepReadonly } from "ts-essentials";

import AgentAbilityIcon from "@/game-val/AgentAbilityIcon.jsx";
import type { Agents } from "@/game-val/models/agents.mjs";
import { containerCss, tabContainerCss } from "@/shared/toggle-tabs.style.mjs";

export const tabLabelCss = () => css`
  padding: var(--sp-1);
  color: var(--shade2);
  cursor: pointer;

  .active & {
    color: var(--shade0);
  }

  .toggle-icon {
    font-size: var(--sp-6);
    width: var(--sp-6);
    height: var(--sp-6);
  }

  ${mobile} {
    .toggle-icon + .toggle-description {
      display: none;
    }
  }
`;

interface AgentAbilityToggleProps {
  agent: DeepReadonly<Agents[number]>;
  ability: string;
  setAbility: (ability: string) => void;
}

export default function AgentAbilityToggle({
  agent,
  ability,
  setAbility,
}: AgentAbilityToggleProps) {
  const { t } = useTranslation();

  const abilityTabOptions = useMemo(() => {
    if (!agent) return [];

    const abilities = agent.abilities;
    if (!abilities) return [];

    return [
      {
        name: "all",
        content: (
          <div className="flex row justify-between align-center gap-1-5 pad-0-3 h-full">
            <span className="toggle-description type-callout--semi">
              {t("common:all", "All")}
            </span>
          </div>
        ),
      },
      ...abilities.map((ability) => {
        return {
          name: ability.key,
          content: (
            <div className="flex row justify-between align-center gap-1-5 pad-0-3 h-full">
              <AgentAbilityIcon ability={ability} className="toggle-icon" />
              <span className="toggle-description type-callout--semi">
                {ability.name}
              </span>
            </div>
          ),
        };
      }),
    ];
  }, [agent, t]);

  const activeAbilityTab = useMemo(() => {
    return abilityTabOptions.findIndex((v) => v.name === ability);
  }, [ability, abilityTabOptions]);

  return (
    <Tabs<string>
      tabOptions={abilityTabOptions}
      onChange={(idx) => {
        const value = abilityTabOptions?.[idx];
        if (!value) return;
        setAbility(value.name);
      }}
      label={t("common:ability", "Ability")}
      activeTab={activeAbilityTab}
      classes={{
        containerClass: containerCss(),
        tabContainerClass: tabContainerCss,
        tabLabelClass: tabLabelCss(),
      }}
    />
  );
}
